<div class="app-container">
    <div *ngIf="loginService.loginStatus"  class="sidebar" [ngClass]="{'sidemenu-container' : !menuCollapsed , 'sidemenu-container-collapsed':menuCollapsed}">
        <app-sidemenu></app-sidemenu>
    </div>
    <div [ngClass]="{'right-container' : loginService.loginStatus && !menuCollapsed , 'right-container-expanded': loginService.loginStatus && menuCollapsed , 'login-screen': !loginService.loginStatus}">
        <div id="header-container" *ngIf="loginService.loginStatus">
            <app-header></app-header>
        </div>
        <div [ngClass]="{'screen-content': loginService.loginStatus, 'login-screen':!loginService.loginStatus}">
            <router-outlet></router-outlet>
        </div>
    </div>
    <div class="mobile-overlay pointer" *ngIf="showOverlay && menuCollapsed == false" (click)="sidepanel.toggleSidemenu()"></div>

    <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</div>