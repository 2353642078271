export const apiURL: any = {

    login: 'managelogin/login',
    getUserData: 'manageusers/',
    changePassword: 'managelogin/edit-password',
    reports: 'apprreports/buildgraph',
    uploadCSV: 'tmatdevices/upload-csv',
    menu: 'managelogin/menu',

    dashboard: {
        analysis: 'dbdashboard/analysis',
        breached: 'regddevices/breached',
        devices_loc: 'regddevices/locations'
    },
    
    mmenuGroups: {
        base: 'mmenugroups/',
        data: 'mmenugroups/object'
    },
    modulesList: {
        base: 'moduleslist/',
        data: 'moduleslist/object'
    },

    fixedValues: {
        base: 'fixedvalues/',
        data: 'fixedvalues/object'
    },

    formModules: {
        base: 'formmodules/',
        data: 'formmodules/object'
    },
    formColumns: {
        base: 'formcolumns/',
        data: 'formcolumns/object'
    },

    reportsList: {
        base: 'reportslist/',
        data: 'reportslist/object'
    },
    rParameters: {
        base: 'rparameters/',
        data: 'rparameters/object'
    },

    sadminTasks: {
        base: 'sadmintasks/',
        data: 'sadmintasks/object'
    },
    staskParams: {
        base: 'staskparams/',
        data: 'staskparams/object'
    },
    
    /*PROJECT SPECIFIC ROUTES*/
    eTelematics: {
        base: 'etelematics/',
        data: 'etelematics/object'
    },
    
    messageTags: {
        base: 'messagetags/',
        data: 'messagetags/object'
    },

    dataModules: {
        base: 'datamodules/',
        data: 'datamodules/object'
    },

    dataFormats: {
        base: 'dataformats/',
        data: 'dataformats/object'
    },

    formulaTags: {
        base: 'formulatags/',
        data: 'formulatags/object'
    },

    tagFormulas: {
        base: 'tagformulas/',
        data: 'tagformulas/object'
    },

    warningTags: {
        base: 'warningtags/',
        data: 'warningtags/object'
    },

    ebmsModules: {
        base: 'ebmsmodules/',
        data: 'ebmsmodules/object'
    },
    
    tmatDevices: {
        base: 'tmatdevices/',
        data: 'tmatdevices/object'
    },

    regdDevices: {
        base: 'regddevices/',
        data: 'regddevices/object'
    },
    eoemDevices: {
        base: 'eoemdevices/',
        data: 'eoemdevices/object'
    },
    editDevices: {
        base: 'editdevices/',
        data: 'editdevices/object'
    },
    seedDevices: {
        base: 'seeddevices/',
        data: 'seeddevices/object'
    },

    initDevices: {
        base: 'tmatdevices/',
        data: 'tmatdevices/object'
    },

    regdVendors: {
        base: 'regdvendors/',
        data: 'regdvendors/object'
    },
    
    firstBuyers: {
        base: 'firstbuyers/',
        data: 'firstbuyers/object'
    },

    deviceMakes: {
        base: 'devicemakes/',
        data: 'devicemakes/object'
    },

    regdDealers: {
        base: 'regddealers/',
        data: 'regddealers/object'
    },

    regdClients: {
        base: 'regdclients/',
        data: 'regdclients/object'
    },

    logMessages: {
        base: 'logmessages/',
        data: 'logmessages/object'
    },

    messageLogs: {
        base: 'messagelogs/',
        data: 'messagelogs/object'
    },

    tagMessages: {
        base: 'tagmessages/',
        data: 'tagmessages/object'
    },

    warningLogs: {
        base: 'warninglogs/',
        data: 'warninglogs/object'
    },
    tagWarnings: {
        base: 'tagwarnings/',
        data: 'tagwarnings/object'
    },

    stickLabels: {
        base: 'sticklabels/',
        data: 'sticklabels/object'
    },

    globalFence: {
        base: 'globalfence/',
        data: 'globalfence/object'
    },

    regdDrivers: {
        base: 'regddrivers/',
        data: 'regddrivers/object'
    },

    storageHubs: {
        base: 'storagehubs/',
        data: 'storagehubs/object'
    },

    addonLogins: {
        base: 'addonlogins/',
        data: 'addonlogins/object'
    },
    
    vendorLogin: {
        base: 'listofusers/',
        data: 'listofusers/object'
    },

    buyersLogin: {
        base: 'listofusers/',
        data: 'listofusers/object'
    },

    dealerLogin: {
        base: 'listofusers/',
        data: 'listofusers/object'
    },

    clientLogin: {
        base: 'listofusers/',
        data: 'listofusers/object'
    },

    storesLogin: {
        base: 'listofusers/',
        data: 'listofusers/object'
    },

    apiAccounts: {
        base: 'apiaccounts/',
        data: 'apiaccounts/object'
    },

    expPayloads: {
        base: 'exppayloads/',
        data: 'exppayloads/object'
    },

    manageUsers: {
        base: 'manageusers/',
        data: 'manageusers/object'
    },
    
    accessRoles: {
        base: 'accessroles/',
        data: 'accessroles/object'
    },
    userPermits: {
        base: 'userpermits/',
        data: 'userpermits/object'
    },

    sysControls: {
        base: 'syscontrols/',
        data: 'syscontrols/object'
    },
    sysSettings: {
        base: 'syssettings/',
        data: 'syssettings/object'
    },
    urlSettings: {
        base: 'urlsettings/',
        data: 'urlsettings/object'
    },

    mviewStores: {
        base: 'mviewstores/',
        data: 'mviewstores/object'
    },
    mviewFences: {
        base: 'mviewfences/',
        data: 'mviewfences/object'
    },
    mviewLabels: {
        base: 'mviewlabels/',
        data: 'mviewlabels/object'
    },
    mviewDriver: {
        base: 'mviewdriver/',
        data: 'mviewdriver/object'
    },

    terminal: {
        device: 'terminal/devicelist',
        details: 'terminal/logs',
        cmd: 'terminal/addcommand'
    },

    testdata: {
        device: 'terminal/devicelist',
        data: 'terminal/testdata'
    }
};
