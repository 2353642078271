import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { 
    path: '',
    pathMatch: 'full' ,
    redirectTo: 'dashboard' 
  },
  
  { 
    path: 'skip',
    redirectTo: ''
  },
  
  { 
    path: 'login', 
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },

  { 
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    data:{
      showFilter:true,
      map_data:{
        entity: 'D',
        device: {
          entity: 'device',
          marker_label: true,
          info_window: 0,
          data:[]
        }
      }
    }
  },

  {
    path: 'moduleSetup', 
    loadChildren: () => import('./pages/double-table/double-table.module').then(m => m.DoubleTableModule), 
    data : {
      filter : { show : true , endpoint : 'fixedValues', payload : 'mModuleList' },
      primary : { endpoint : 'mmenuGroups' , payload : null, filterKey : 'main_group', extraKeys: null },
      secondary : { endpoint : 'modulesList' , payload : null }
    }
  },
  {
    path: 'fixedValues',
    loadChildren: () => import ('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'fixedValues' , payload : 'allFixedLists', filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'formModules',
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'formModules' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path:'formColumns',
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      filter : { show : true , endpoint : 'formModules', payload : null },
      primary : { endpoint : 'formColumns' , payload : null, filterKey : 'form_code', extraKeys: null }
    }
  },

  {
    path: 'reportsList',
    loadChildren: () => import('./pages/double-table/double-table.module').then(m => m.DoubleTableModule),
    data : {
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'reportsList' , payload : null, filterKey : null, extraKeys: null },
      secondary : { endpoint : 'rParameters' , payload : null }
    }
  },
  
  {
    path: 'expPayloads',
    loadChildren: () => import ('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'expPayloads' , payload : null, filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'sadminTasks', 
    loadChildren: () => import('./pages/double-table/double-table.module').then(m => m.DoubleTableModule), 
    data : {
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'sadminTasks' , payload : null, filterKey : 'task_code', extraKeys: null },
      secondary : { endpoint : 'staskParams' , payload : null }
    }
  },
  
  
  
  //Models & Tags
  {
    path: 'eTelematics', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Models & Tags', title: 'Telematics Model'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'eTelematics' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'messageTags', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Models & Tags', title: 'Message Tags'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'messageTags' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  // {
  //   path: 'dataFormats', 
  //   loadChildren: () => import('./pages/double-table/double-table.module').then(m => m.DoubleTableModule), 
  //   data : {
  //     page: {group: 'Models & Tags', title: 'Data Formats'},
  //     filter : { show : false , endpoint : null , payload : null },
  //     primary : { endpoint : 'eTelematics' , payload : null, filterKey : null, extraKeys: null },
  //     secondary : { endpoint : 'dataFormats' , payload : null }
  //   }
  // },

  {
    path:'dataFormats',
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Models & Tags', title: 'Data Definitions'},
      filter : { show : true , endpoint : 'eTelematics' , payload : null },
      primary : { endpoint : 'dataFormats' , payload : null, filterKey : 'model_code', extraKeys: null }
    }
  },

  {
    path: 'tagFormulas', 
    loadChildren: () => import('./pages/double-table/double-table.module').then(m => m.DoubleTableModule), 
    data : {
      page: {group: 'Models & Tags', title: 'Tag Formulas'},
      filter : { show : true , endpoint : 'eTelematics' , payload : null },
      primary : { endpoint : 'formulaTags' , payload : null, filterKey : 'model_code', extraKeys: null },
      secondary : { endpoint : 'tagFormulas' , payload : null }
    }
  },

  {
    path: 'warningTags', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Models & Tags', title: 'Warning Tags'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'warningTags' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'ebmsModules', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Models & Tags', title: 'EBMS Modules'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'ebmsModules' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  //Devices

  {
    path: 'tmatDevices', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Devices', title: 'Telematic Devices'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'tmatDevices' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'regdDevices', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Devices', title: 'Registered Devices'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'regdDevices' , payload : null, filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'eoemDevices', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Portal Setup', title: 'OEM Devices'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'eoemDevices' , payload : null, filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'editDevices', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Portal Setup', title: 'Edit Devices'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'editDevices' , payload : null, filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'seedDevices', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Portal Setup', title: 'Seed Devices'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'seedDevices' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'initDevices', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Devices', title: 'Control Devices'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'initDevices' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  //Messages
  {
    path: 'messageLogs', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Messages', title: 'Message Logs'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'messageLogs' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'tagMessages', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Messages', title: 'Tag Messages'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'tagMessages' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'warningLogs', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Messages', title: 'Warning Logs'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'warningLogs' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'tagWarnings', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Warnings', title: 'Tag Warnings'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'tagWarnings' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  //Master Setup 
  {
    path: 'regdVendors', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Master Setup', title: 'IoT Vendors'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'regdVendors' , payload : null, filterKey : null, extraKeys: null }
    }
  },
  
  {
    path: 'firstBuyers', 
    loadChildren: () => import('./pages/double-table/double-table.module').then(m => m.DoubleTableModule), 
    data : {
      page: {group: 'Master Setup', title: 'First Buyers'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'firstBuyers' , payload : null, filterKey : null, extraKeys: null },
      secondary : { endpoint : 'deviceMakes' , payload : null }
    }
  },

  {
    path: 'regdDealers', 
    loadChildren: () => import('./pages/double-table/double-table.module').then(m => m.DoubleTableModule), 
    data : {
      page: {group: 'Master Setup', title: 'Regd Dealers'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'regdDealers' , payload : null, filterKey : null, extraKeys: null },
      secondary : { endpoint : 'regdDevices' , payload : null }

    }
  },

  {
    path: 'storageHubs', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Master Setup', title: 'Storage Hubs'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'storageHubs' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'stickLabels', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Master Setup', title: 'Device Labels'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'stickLabels' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'globalFence', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Master Setup', title: 'Global Fence'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'globalFence' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'regdDrivers', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Master Setup', title: 'Regd Drivers'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'regdDrivers' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  //Manage Users
  {
    path: 'manageUsers', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      page: {group: 'Manage Users', title: 'Admin Users'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'manageUsers' , payload : null, filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'addonLogins', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      page: {group: 'Manage Users', title: 'Addon Users'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'addonLogins' , payload : 'addonsLogin', filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'accessRoles', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'accessRoles' , payload : null, filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'userPermits', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'userPermits' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  //Manage Logins 
  {
    path: 'vendorLogin', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      page: {group: 'Manage Logins', title: 'Vendor Logins'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'vendorLogin' , payload : 'vendorLogin', filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'vendorLogin', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      page: {group: 'Manage Logins', title: 'Vendor Logins'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'vendorLogin' , payload : 'vendorLogin', filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'buyersLogin', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      page: {group: 'Manage Logins', title: 'OEMS Logins'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'buyersLogin' , payload : 'oemLogin', filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'dealerLogin', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      page: {group: 'Manage Logins', title: 'Dealer Login'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'dealerLogin' , payload : 'dealerLogin', filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'clientLogin', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      page: {group: 'Manage Logins', title: 'Branch Logins'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'clientLogin' , payload : 'clientLogin', filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'storesLogin', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      page: {group: 'Manage Logins', title: 'Store Logins'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'storesLogin' , payload : 'storesLogin', filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'apiAccounts', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      page: {group: 'Manage Logins', title: 'API Accounts'},
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'apiAccounts' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'mviewStores', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Portal Setup', title: 'Device Stores'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'mviewStores' , payload : null, filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'mviewFences', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Portal Setup', title: 'Device Fences'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'mviewFences' , payload : null, filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'mviewLabels', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Portal Setup', title: 'Device Labels'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'mviewLabels' , payload : null, filterKey : null, extraKeys: null }
    }
  },
  {
    path: 'mviewDriver', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule),
    data : {
      page: {group: 'Portal Setup', title: 'Device Driver'},
      filter : { show : false , endpoint : null , payload : null },
      primary : { endpoint : 'mviewDriver' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'sysControls', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'sysControls' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  {
    path: 'sysSettings', 
    loadChildren: () => import('./pages/single-table/single-table.module').then(m => m.SingleTableModule), 
    data : {
      filter : { show : false , endpoint : null, payload : null },
      primary : { endpoint : 'sysSettings' , payload : null, filterKey : null, extraKeys: null }
    }
  },

  { path: 'change-password' , loadChildren: () => import('./pages/changepassword/changepassword.module').then(m => m.ChangepasswordModule) },

  { path: 'reports'         , loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule) , data: {page: 'View Reports', type: 'REP'  , list: 'reportsList'} },
  { path: 'graphs'          , loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule) , data: {page: 'View Graphs' , type: 'GRAPH', list: 'graphsList' } },
  
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule) },

  { path: 'tasks', loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksModule) , data: {page: 'Tasks'}},

  { path: 'terminal', loadChildren: () => import('./pages/terminal/terminal.module').then(m => m.TerminalModule) },

  { path: 'testdata', loadChildren: () => import('./pages/testdata/testdata.module').then(m => m.TestdataModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
