import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { LoginStatusService } from 'src/app/services/login-status/login-status.service';

//API URL IMPORT
import { config } from '../../../environments/config'

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {

  httpOptions: any = {
    headers:{
      'Content-Type': 'application/json',
      'panel': 'AP',
      'Authorization': ''     
    },
    
  }
  constructor(
    private httpClient: HttpClient,
    public sharedService: LoginStatusService,
    ) {}  


  handleError(error: HttpErrorResponse) {
    console.log('handle',error);
    
    let errorObj = {
      status:false,
      msg: ''
    };
    if(error.error instanceof ErrorEvent) {
      console.log('client');
      
      errorObj.msg = error.error.message; //Client Side Errors
    } else { 
      console.log('server');
      
      errorObj.msg = error.error.message; 
    }  
    this.sharedService.sessionExpired('session')
    return throwError(errorObj)
  }


  public request(endpoint: string , payload: Object = {},method:string = 'POST'){
    
    if(endpoint !== 'managelogin/login'){ 
      var data = localStorage.getItem(config.license_code);
      if(data !== null){
        var LocalStorage: any = JSON.parse(data)
        this.httpOptions['headers']['Authorization'] = 'Bearer ' + LocalStorage['web_token'];
        this.httpOptions['headers']['user_name'] = LocalStorage['user_details'].user_name;
        if(LocalStorage['user_details'].hub_store)
          this.httpOptions['headers']['hub_store'] = LocalStorage['user_details'].hub_store;
      }   

      if(sessionStorage.getItem(config.license_code)){
        this.httpOptions['headers'] = Object.assign(this.httpOptions['headers'],JSON.parse(sessionStorage.getItem(config.license_code)).header_info)
      }
    }
    this.httpOptions['body'] = payload;
    return this.httpClient.request(method,config.API_HOST + '/api/' + endpoint,this.httpOptions).pipe(catchError(this.handleError.bind(this)));
  
  }

}
