import { Pipe, PipeTransform } from '@angular/core';
import { language } from 'src/app/models/languages';
import {config} from 'src/environments/config'

@Pipe({
  name: 'translation'
})
export class TranslationPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return this.translate(value);
  }

  private translate(key: string): string {
    // private perform translation
    let translation = key;
    let localItem :any = localStorage.getItem(config.license_code);
    let localObj = JSON.parse(localItem);
    let currentLang = localObj.user_details.user_lang;

    if (language[key] && language[key][currentLang]) {
        return language[key][currentLang];
    }

    return translation;
}

}
