export const apiPayload: any = {
    sidemenu : {
        data_owner: 'SYS'
    },

    allFixedLists : {
        list_name : 'ALLLISTS'
    },

    mModuleList : {
        list_name : 'MAINMENU'
    },

    reportsList : {
        report_type: 'REP'
    },

    graphsList : {
        report_type: 'GRAPH'
    },

    addonsLogin : {
        id : 'ADNLUSER'
    },

    vendorLogin : {
        id : 'VENUSERS'
    },
    
    oemLogin : {
        id : 'OEMUSERS'
    },

    dealerLogin : {
        id : 'DLRAGRAC'
    },

    clientLogin: {
        id : 'AGRUSERS'
    },
    
    storesLogin : {
        id : 'HUBUSERS'
    }
};
