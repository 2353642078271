import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginStatusService } from 'src/app/services/login-status/login-status.service';
import { menuItems } from 'src/app/models/headermenu'
import { config } from 'src/environments/config';
import { SidemenuService } from 'src/app/services/sidemenu/sidemenu.service';
import { HttpRequestService } from 'src/app/services/http-request/http-request.service';
import { apiURL } from 'src/app/models/apis';
import { apiPayload } from 'src/app/models/payload';
import { sidemenuItems } from 'src/app/models/sidemenu';
import { SidepanelsService } from 'src/app/services/sidepanels/sidepanels.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menus: any = [];
  showMenu: boolean = false;

  user_name: string = '';
  constructor(
    public sharedService: LoginStatusService,
    public sidemenuService: SidemenuService,
    public sidepanel: SidepanelsService,
  ) {}

  ngOnInit(): void {
    var data = localStorage.getItem(config.license_code);
    if(data !== null){
      var LocalStorage: any = JSON.parse(data)
      this.user_name = LocalStorage['user_details']['user_name']
    }
  };

  // getHeadermenu(): void {
  //   this.httpService.request(apiURL['menu'], apiPayload['headermenu']).subscribe((response : any) => {
  //     if (response['status'] === 'success') {
  //         if(response['data'].length){
  //           this.menus = response.data;
  //         }else{
  //           this.menus = [];
  //         }
  //         this.showMenu = true;
  //     }else {
  //       if(response['success'] === false)
  //         this.sharedService.sessionExpired('session')    
  //     }
  //   })
  // };
}
