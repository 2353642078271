<div class="side-panel">
    <div class="panel-header">
        <span class="header">Geofence Breach ({{data.data.length}})</span>
        <button class="btn btn-default close" (click)="closeSidePanel()">
            <fa-icon icon="times"></fa-icon>
        </button>
    </div>
    <div class="panel-body">
        <app-table [table_data]="data" ></app-table>
    </div>
</div>