
<div class="table-responsive scroll-table bg-white" *ngIf="table_data.data.length > 0">
    <table class="table">
        <thead>
            <tr>
                <ng-container  *ngFor="let head of table_data.head | orderBy: 'sort_seqno' ">
                    <th scope="col" class="fit">{{head.name}}</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of table_data.data" >
                <ng-container *ngFor="let head of table_data.head | orderBy: 'sort_seqno'">
                    <td [ngClass] = "{'width-fix' : head['field'] == 'string'}">{{data[head['field']]}}</td>
                </ng-container>
            </tr> 
        </tbody>
    </table>
</div>
<div class="no-data" *ngIf="table_data.data.length == 0">
    <h4>No Data</h4>
</div>