<div class="filter-container" *ngIf="apln_code != 'VENAPP'">
   
    <div class="form-group">
        <ng-select
            [placeholder] = "parent_filter.placeholder"
            name = "{{parent_filter.field_name}}" 
            [(ngModel)] = "payload[parent_filter.field_name]" 
            (ngModelChange) = "getOptions('CHILD' , child_filter , 'P')"
            [disabled] = "parent_filter.disabled"
            [clearable] = "parent_filter.clearable">
            <ng-option *ngFor="let option of parent_filter.options | orderBy: 'code'" [value]="option.code">{{option.name}}</ng-option>
        </ng-select>
    </div>
    <div class="form-group">
        <ng-select
            [placeholder] = "child_filter.placeholder"
            name = "{{child_filter.field_name}}" 
            [(ngModel)] = "payload[child_filter.field_name]" 
            (ngModelChange) = "storeHeaderInfo()"
            [disabled] = "child_filter.disabled"
            [clearable] = "parent_filter.clearable">
            <ng-option *ngFor="let option of child_filter.options | orderBy: 'code'" [value]="option.code">{{option.name}}</ng-option>
        </ng-select>
    </div>
</div>