import { Component, OnInit } from '@angular/core';
import { filters } from 'src/app/models/headerfilters';
import { apiURL } from 'src/app/models/apis'
import { HttpRequestService } from 'src/app/services/http-request/http-request.service'
import { LoginStatusService } from 'src/app/services/login-status/login-status.service';
import { config } from 'src/environments/config';
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

@Component({
  selector: 'app-header-filters',
  templateUrl: './header-filters.component.html',
  styleUrls: ['./header-filters.component.scss']
})
export class HeaderFiltersComponent implements OnInit {

  /*VAR NAME CHANGES BASED ON PROJECT*/
  LocalStorage: any = {};
  SessionStorage: any = {};

  /*VAR NAME CHANGES BASED ON PROJECT*/
  apln_code: string = null;
  buyer_code: string = null;
  dealer_id: string = null;

  parent_filter : any = filters.parent;
  child_filter  : any = filters.child;

  payload: any = {};
  optionsPayload: any = {};

  constructor(
    private _location: Location,
    private httpService: HttpRequestService,
    private sharedService: LoginStatusService,
    private router: Router,
    private subscribe: SubscriptionService
  ) { }

  ngOnInit(): void {
    var data = localStorage.getItem(config.license_code);
    this.LocalStorage = JSON.parse(data);

    /*USER DETAILS VAR FOR LEVEL CHANGES BASED ON PROJECT*/
    this.apln_code = this.LocalStorage.user_details.apln_code;
    this.buyer_code = this.LocalStorage.user_details.buyer_code;
    this.dealer_id = this.LocalStorage.user_details.dealer_id;
    
    /*GET CURRENT HEADER INFO FROM SESSION STORAGE*/
    var session = sessionStorage.getItem(config.license_code);
    this.SessionStorage = (session) ? JSON.parse(session).header_info : {header_info: {}} ;
    
    if(this.apln_code == 'VENAPP'){
      var obj = {
        header_info:{
          apln_code: 'VENAPP'
        }
      };
      sessionStorage.setItem(config.license_code ,JSON.stringify(obj));
    }else{
      this.getOptions('PARENT', this.parent_filter);
    }
  }

  getOptions(type: string, filter: any, action: string = null): void{
    
    var endpoint =  apiURL[filter.input_link].base;

    /*SEND LEVEL 1 CHOSEN VALUE FOR LAVEL 2 FILTERING*/
    if(type === 'CHILD'){
      this.optionsPayload[this.parent_filter.field_name] = this.payload[this.parent_filter.field_name];
    }

    this.httpService.request(endpoint + 'select', this.optionsPayload).subscribe(
      (response: any) => {      
        if(response['status'] === 'success'){
          filter.options = response['data'];
          this.optionsPayload = {};
          
          if(filter.options.length > 0){
            /*BASED ON APLN CODE SET PARENT AND CHILD LEVEL VALUES*/
            if(type == 'PARENT'){
              this.payload[filter.field_name] = (this.SessionStorage[filter.field_name]) ? this.SessionStorage[filter.field_name] : filter.options[0].code ;
              this.setPayload();
            } /*STORE BOTH LEVEL VALUES IN SESSION STORAGE*/
            else if(type == 'CHILD'){              
              this.payload[filter.field_name] = (this.SessionStorage[filter.field_name] && action == 'I') ? this.SessionStorage[filter.field_name] : filter.options[0].code ;
                this.storeHeaderInfo();
            }
          }
          else{
            if(type == 'CHILD'){
              this.payload[filter.field_name] = '';
              this.storeHeaderInfo();
            }
          }         
        } else {
          if(response['success'] === false){
            this.sharedService.sessionExpired('session')
          }
          this.optionsPayload = {};
        }
      }
    )
  };

  setPayload(): void{
    /*CASE VALUES CHANGES BASED ON PROJECT REQUIREMNET*/
    switch(this.apln_code) {
      case 'SYSAPP' : 
        this.parent_filter.disabled = false;
        this.child_filter.disabled = false;
        this.getOptions('CHILD', this.child_filter, 'I');
        break;
      case 'USRAPP' : 
        this.parent_filter.disabled = false;
        this.child_filter.disabled = false;
        this.getOptions('CHILD', this.child_filter, 'I');
        break;
      case 'OEMAPP' :
        this.payload[this.parent_filter.field_name] = this.buyer_code;
        this.parent_filter.disabled = true;
        this.child_filter.disabled = false;
        this.getOptions('CHILD', this.child_filter, 'I');
        break;
      default:
        this.payload[this.parent_filter.field_name] = this.buyer_code;
        this.payload[this.child_filter.field_name] = this.dealer_id;
        this.parent_filter.disabled = true;
        this.child_filter.disabled = true;
        this.storeHeaderInfo();
        break;
    }
  };

  storeHeaderInfo(): void{
  
    var obj = {
      header_info : {
        apln_code : this.apln_code,
        buyer_code   : this.payload[this.parent_filter.field_name],
        dealer_id   : this.payload[this.child_filter.field_name]
      }
    }
    sessionStorage.setItem(config.license_code ,JSON.stringify(obj));
    this.subscribe.hf_status = true;
    /*Refresh Page To Retrieve Data based on chosen filters*/
    var path = this._location.path();
    if(path == '/login')
      path = '/dashboard';
      
    this.router.navigateByUrl('/skip', {skipLocationChange: true}).then(()=>
      this.router.navigate([path]));
  }
}
