import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'naFilter'
})
export class NaFilterPipe implements PipeTransform {

  transform(value: any | null ): any {
    if( value == null || value == undefined || value == "") return 'NA';
    else return value;
  }

}
