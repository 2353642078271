import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import {NaFilterPipe} from '../pipes/na-filter/na-filter.pipe'
import { LOCALE_ID } from "@angular/core";
import { LoaderModule } from '../components/loader/loader.module';
import { faFileDownload, faSearch, faSyncAlt , fas , faFilePdf, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { NgPipesModule } from 'ngx-pipes';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    NaFilterPipe
  ],
  imports: [
    CommonModule
  ],
  providers :[{ provide: LOCALE_ID, useValue: "en-IN" }],
  exports: [
    CommonModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule,
    NaFilterPipe,
    LoaderModule,
    NgPipesModule,
    RouterModule,
    NgSelectModule
  ]
})
export class SharedModule { 
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas,fab);
    library.addIcons(
      faFileDownload,
      faSearch,
      faSyncAlt,
      faFilePdf,
      faTimes,
      faTrash
    );
  }
}
