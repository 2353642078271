<div class="sidebar" [ngClass] = "{'sidebar-collapsed' : menuCollapsed}">
    <div class="logo-container">
        <img src="https://instaswapcdn.s3.ap-south-1.amazonaws.com/logo-color-instaswap.png">
    </div>
    <ul class="side-menu"  *ngIf="showMenu">
        <li class="slide" routerLinkActive="active" *ngFor="let item of menus | orderBy: 'mmenu_code'" [ngClass]="{'is-expanded': selectedOption == item.mmenu_code}">
            <ng-container *ngIf="item.items.length === 0 ">
                <a class="side-menu-item" [ngbTooltip]="(menuCollapsed) ? item.mmenu_name:''" [routerLink]="'/' + item.mmenu_link" (click)="collapseMenu('M')">
                    <fa-icon [icon]=item.mmenu_icon [ngClass]="item.mmenu_color" class="side-menu-icon"></fa-icon>
                    <span class="side-menu-label">{{item.mmenu_name}}</span>
                </a>
            </ng-container>
            <ng-container *ngIf="item.items.length">
                <a class="side-menu-item pointer" (click) = "openSubmenu(item.mmenu_code, item.items)" [ngbTooltip]="(menuCollapsed) ? item.mmenu_name:''">
                    <span class="side-menu-icon">
                        <fa-icon [icon]=item.mmenu_icon [ngClass]="item.mmenu_color" class="side-menu-icon"></fa-icon>
                    </span>
                    <span class="side-menu-label">{{item.mmenu_name}}</span>
                    <fa-icon icon = "chevron-right" class="dropdown-icon open-menu angle"></fa-icon>
                </a>
                <ul class="slide-menu">
                    <li class="pointer" routerLinkActive="active" *ngFor = "let subitem of item.items | orderBy: 'sort_seqno'" [ngbTooltip]="(menuCollapsed) ? subitem.module_name:''"  [routerLink]="'/' + subitem.module_link"  (click)="collapseMenu('S')">
                        <fa-icon [icon] = subitem.module_icon class="sub-menu-icon text-danger"></fa-icon>
                        <span class="slide-item">{{subitem.module_name}}</span>
                    </li> 
                </ul>
            </ng-container>
        </li>
    </ul>
    <div class="powered-by" [ngClass] = "{'d-none' : menuCollapsed}">
        <!-- <span>Powered By</span> -->
        <!-- <img [src]="poweredBy"/> -->
    </div>
</div>