export const sidemenuItems: any = {
    poweredBy: 'https://res.cloudinary.com/emurontech/image/upload/v1609401829/logos/logo_emuron.png',
    menus: [
        {
            "items" : [],
            "mmenu_code" : "SM01",
            "mmenu_color": "text-success",
            "mmenu_icon" : "home",
            "mmenu_link" : 'dashboard',
            "mmenu_name" : "Dashboard"
        },
        {
            "items" : [],
            "mmenu_code" : "SM02",
            "mmenu_color": "text-warning",
            "mmenu_icon" : "file-alt",
            "mmenu_link" : "reports",
            "mmenu_name" : "View Reports"
        },
        {
            "items" : [],
            "mmenu_code" : "SM03",
            "mmenu_color": "text-warning",
            "mmenu_icon" : "chart-line",
            "mmenu_link" : "graphs",
            "mmenu_name" : "View Graphs"
        },
        {
            "items" : [
                {
                    "module_code" : "SS0001",
                    "module_link" : "eTelematics",
                    "module_name" : "Telematics Models",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0002",
                    "module_link" : "messageTags",
                    "module_name" : "Message Tags",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0003",
                    "module_link" : "dataFormats",
                    "module_name" : "Data Defination",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0004",
                    "module_link" : "tagFormulas",
                    "module_name" : "Tag Formulas",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0005",
                    "module_link" : "warningTags",
                    "module_name" : "Warning Tags",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0006",
                    "module_link" : "ebmsModules",
                    "module_name" : "BMS Modules",
                    "module_icon" : "car-battery"
                }

            ],
            "mmenu_code" : "SM04",
            "mmenu_color": "text-danger",
            "mmenu_icon" : "tag",
            "mmenu_link" : null,
            "mmenu_name" : "Models & Tags"
        },
        {
            "items" : [
                {
                    "module_code" : "SS0007",
                    "module_link" : "tmatDevices",
                    "module_name" : "Telematic Devices",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0009",
                    "module_link" : "regdDevices",
                    "module_name" : "Registered Devices",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0010",
                    "module_link" : "initDevices",
                    "module_name" : "Control Devices",
                    "module_icon" : "car-battery"
                }
            ],
            "mmenu_code" : "SM05",
            "mmenu_color": "text-danger",
            "mmenu_icon" : "microchip",
            "mmenu_link" : null,
            "mmenu_name" : "Devices"
        },
        {
            "items" : [
                {
                    "module_code" : "SS0011",
                    "module_link" : "messageLogs",
                    "module_name" : "Message Logs",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0012",
                    "module_link" : "tagMessages",
                    "module_name" : "Tag Messages",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0013",
                    "module_link" : "warningLogs",
                    "module_name" : "Warning Logs",
                    "module_icon" : "car-battery"
                },
            ],
            "mmenu_code" : "SM06",
            "mmenu_color": "text-danger",
            "mmenu_icon" : "envelope",
            "mmenu_link" : null,
            "mmenu_name" : "Messages"
        },
        {
            "items" : [
                {
                    "module_code" : "SS0014",
                    "module_link" : "firstBuyers",
                    "module_name" : "Manufacturers",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0015",
                    "module_link" : "regdDealers",
                    "module_name" : "Dealer/Aggrigator",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0016",
                    "module_link" : "storageHubs",
                    "module_name" : "Storage Hubs",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0017",
                    "module_link" : "stickLabels",
                    "module_name" : "Deivce Labels",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0018",
                    "module_link" : "globalFence",
                    "module_name" : "Geofence",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0019",
                    "module_link" : "regdDrivers",
                    "module_name" : "Riders",
                    "module_icon" : "car-battery"
                }

            ],
            "mmenu_code" : "SM07",
            "mmenu_color": "text-danger",
            "mmenu_icon" : "cog",
            "mmenu_link" : null,
            "mmenu_name" : "Master Setup"
        },
        {
            "items" : [
                {
                    "module_code" : "SS0020",
                    "module_link" : "manageUsers",
                    "module_name" : "Admin Users",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0021",
                    "module_link" : "listofUsers",
                    "module_name" : "Addon Users",
                    "module_icon" : "car-battery"
                }
            ],
            "mmenu_code" : "SM09",
            "mmenu_color": "text-danger",
            "mmenu_icon" : "user-cog",
            "mmenu_link" : null,
            "mmenu_name" : "Manage Users"
        },
        {
            "items" : [
                {
                    "module_code" : "SS0022",
                    "module_link" : "buyersLogin",
                    "module_name" : "OEMS Logins",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0023",
                    "module_link" : "dealerLogin",
                    "module_name" : "Dealer Logins",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0024",
                    "module_link" : "clientLogin",
                    "module_name" : "Branch Logins",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0025",
                    "module_link" : "storesLogin",
                    "module_name" : "Store Logins",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0026",
                    "module_link" : "apiAccounts",
                    "module_name" : "API Logins",
                    "module_icon" : "car-battery"
                }
            ],
            "mmenu_code" : "SM10",
            "mmenu_color": "text-danger",
            "mmenu_icon" : "user-cog",
            "mmenu_link" : null,
            "mmenu_name" : "Manage Logins"
        },
        {
            "mmenu_code" : "SM11",
            "mmenu_color": "text-danger",
            "mmenu_icon" : "user-cog",
            "mmenu_link" : null,
            "mmenu_name" : "Portal Setup",
            "items" : [
                {
                    "module_code" : "SS0022",
                    "module_link" : "mviewStores",
                    "module_name" : "Device Store",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0023",
                    "module_link" : "mviewFences",
                    "module_name" : "Device Fence",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0024",
                    "module_link" : "mviewLabels",
                    "module_name" : "Device Labels",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0025",
                    "module_link" : "mviewDriver",
                    "module_name" : "Device Driver",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0026",
                    "module_link" : "editDevices",
                    "module_name" : "Edit Devices",
                    "module_icon" : "car-battery"
                },
                {
                    "module_code" : "SS0027",
                    "module_link" : "seedDevices",
                    "module_name" : "Seed Devices",
                    "module_icon" : "car-battery"
                }
            ]
        }
    ]
}