import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  //HEADER FILTER STATUS
  public hf_status: boolean = false;

  //TASKS HANDLING
  private _task_loader : BehaviorSubject<any> = new BehaviorSubject(false);
  task_loader = this._task_loader.asObservable();

  private _task_summary : BehaviorSubject<any> = new BehaviorSubject({});
  task_summary = this._task_summary.asObservable();

  public display_mode: string = "DM";

  constructor() { }

  updateTaskData(value: boolean, data: any): void{
    this._task_loader.next(value);
    this._task_summary.next(data);
  };

  setDisplayMode(){
    if(screen.availWidth <= 768){
      if(screen.orientation.type == 'portrait-primary'){
        this.display_mode = "PM";
      }
      else{
        this.display_mode = "LM";
      }
    }else{
      this.display_mode = 'DM';
    }
  };
  
}
