<div id="header" class="container-fluid">
    <nav class="navbar navbar-expand-sm h-100">
        <button type="button" class="btn btn-default sidebar-collapse" (click)="sidepanel.toggleSidemenu()">
            <fa-icon icon="outdent" class="menu-collapse"></fa-icon>
        </button>
        <app-header-filters class="ml-auto"></app-header-filters>
        <!-- <ul class="navbar-nav h-100 ml-2"  *ngIf="showMenu">
            <ng-container *ngFor="let item of menus | orderBy: 'mmenu_code'">
                <li class="nav-item" routerLinkActive="active" *ngIf="item.items.length === 0 ">
                    <a class="nav-link" [routerLink]="'/' + item.mmenu_link">{{item.mmenu_name}}</a>
                </li>
                <li class="nav-item" routerLinkActive="active" ngbDropdown display="dynamic" placement="bottom-right"  *ngIf="item.items.length">
                    <a class="nav-link pointer" [id]="item.mmenu_code" ngbDropdownToggle>
                        {{item.mmenu_name}}
                    </a>
                    <div ngbDropdownMenu aria-labelledby="item.mmenu_code" class="dropdown-menu">
                        <a ngbDropdownItem *ngFor="let subitem of item.items | orderBy: 'sort_seqno'" [routerLink]="'/' + subitem.module_link">{{subitem.module_name}}</a>
                    </div>
                </li>
            </ng-container>
        </ul> -->
        <div class="user-area h-100">
            <span class="text-center">{{user_name}}</span>
            <div class="d-flex align-items-center h-100" ngbDropdown display="dynamic" placement="bottom-right">
                <a class="nav-link pointer p-0" id="userMenu" ngbDropdownToggle id="pt-color"> 
                    <fa-icon icon="user-circle" class="header-icon pl-2 pr-0"></fa-icon>
                </a>
                <div ngbDropdownMenu aria-labelledby="userMenu" class="dropdown-menu">
                    <a ngbDropdownItem routerLink="/profile">My Profile</a>
                    <a ngbDropdownItem routerLink="/change-password">Change Password</a>
                    <a ngbDropdownItem class="pointer" (click)="sharedService.sessionExpired('logout')">Logout</a>
                </div>
            </div>
        </div>
    </nav>
</div>