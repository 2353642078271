import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { LoginStatusService } from 'src/app/services/login-status/login-status.service';
import { SidemenuService } from "src/app/services/sidemenu/sidemenu.service";
import { config } from 'src/environments/config';
import { SidepanelsService } from 'src/app/services/sidepanels/sidepanels.service';
import { SubscriptionService } from 'src/app/services/subscription/subscription.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  loginStatus!: number;
  menuCollapsed: any;
  showOverlay: boolean = false;

  constructor(
    public loginService: LoginStatusService,
    private _router: Router,
    public sidemenuService: SidemenuService,
    private subscribe: SubscriptionService,
    public sidepanel: SidepanelsService

    ){}

  ngOnInit(): void {
    var stored_data = localStorage.getItem(config.license_code);

    if(stored_data !== null){
      var license_code = JSON.parse(stored_data);
      this.loginStatus = parseInt(license_code.login_status);
      if (this.loginStatus) {
        this.loginService.loginStatus = 1;
      } 
      else {
        this._router.navigate(['/login'])
      }
    }
    else{
      this._router.navigate(['/login'])
    }

    this.sidepanel.collapsed.subscribe((value)=>{
      this.menuCollapsed = value;
    });

    this.subscribe.setDisplayMode();

    if(this.subscribe.display_mode == 'PM')
      this.showOverlay = true;
    else
      this.showOverlay = false;
    if(this.subscribe.display_mode == 'PM' || this.subscribe.display_mode == 'LM'){
      this.sidepanel.toggleSidemenu()
    }

    // this._router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((NavigationEnd) => {
    //   if(!this.menuCollapsed){
    //     this.sidemenuService.toggleSidemenu()
    //   }
    // });
  }
}
